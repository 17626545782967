import React from 'react';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        <strong>宮城県、熊本県、山口県、長崎県</strong>
        にお住まいの方限定で、キャンペーン期間中に決済画面でペイディの翌月あと払い、
        <a
          href="https://paidy.com/landing/plus_3pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ３回あと払い
        </a>
        、もしくは
        <a
          href="https://paidy.com/landing/paidycard/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディカード
        </a>
        を利用でお買い物すると、抽選で最大10万円キャッシュバックが当たる。
        <br />
        <br />
        ※キャンペーン期間中に上記条件でお買い物された方に、8月31日までに抽選で最大10万円キャッシュバックを実施。8月31日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年7月1日（金）0:00～8月14日（日）23:59',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・宮城県、熊本県、山口県、長崎県にお住まいの方限定となります。
        <br />
        ・キャッシュバックの当選はお一人一回までで、期間中のお買い物総額を対象として抽選でキャッシュバックされます（最大10万円）。
        <br />
        ・7月中にお買い物をされた場合もキャッシュバックの反映は8月末頃となり、8月分のご請求金額より差し引かれますのでご了承ください。
        <br />
        ・キャッシュバックは、ペイディのご利用に応じた請求金額より差し引く方法による付与されます。いかなる場合でも、キャッシュバック金額を現金で交付することはできませんのでご了承ください。
        <br />
        ・Amazonでのお買い物の場合、期間中に発送（出荷）された商品の総額がキャンペーン対象となります。Amazon
        Primeのお申し込み、Amazon
        Musicの定額ストリーミングサービスのプラン料金など期間中に決済の発生しないサービスの支払いはキャッシュバックの対象外となります。キャンセルされた商品は、キャッシュバックの対象外となります。
        <br />
        ・当選された場合のキャッシュバックは、2022年8月31日頃MyPaidyもしくはペイディアプリに反映され、8月末締めの請求金額より差し引かれます。8月末締の請求金額がキャッシュバック金額に満たない場合、9月末締以降のご請求から差し引かれます。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <a
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </a>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <a
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </a>
        へご連絡ください。
      </>
    ),
  },
];

export default function JulyCondition() {
  return <SectionCampaignCondition data={DATA} />;
}
