import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import styles from './JulyTopBanner.module.scss';

export default function JulyTopBanner() {
  return (
    <section className={styles.section}>
      <div className={styles.img} />
      <OutboundLink
        href="https://paidy.com/shop/"
        rel="noopener noreferrer"
        target="_blank"
        className={styles.btn}
      >
        使えるお店を探す
      </OutboundLink>
    </section>
  );
}
