import React, { useEffect, useState } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Qr from 'src/images/qr-white-bg.png';
import PaidyIcon from 'src/images/paidy-pink.svg';
import AppStore from 'src/images/badge-app-store.png';
import PlayStore from 'src/images/badge-play-store.png';
import styles from './JulyAppDownload.module.scss';

function getMobileOperatingSystem() {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  } catch (_error) {
    return 'unknown';
  }
}

function AppBadge({ device = 'Android' }) {
  return (
    <OutboundLink
      href="https://paidy.onelink.me/GTiS/GeneralCBCP"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={device === 'Android' ? PlayStore : AppStore}
        alt={device === 'Android' ? 'play-store' : 'app-store'}
        width={137}
        height={39}
      />
    </OutboundLink>
  );
}

export default function JulyAppDownload() {
  const [device, setDevice] = useState('unknown');

  useEffect(() => {
    setTimeout(() => {
      setDevice(getMobileOperatingSystem());
    }, 100);
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img src={Qr} alt="qr" width={130} height={130} className={styles.qr} />
        <div className={styles.containerRight}>
          <div className={styles.containerPaidyLogo}>
            <img src={PaidyIcon} alt="paidy-icon" width={51} height={51} />
            <h2 className={styles.text}>
              ペイディアプリで
              <br />
              もっと便利に。
            </h2>
          </div>
          <div className={styles.containerBadges}>
            {['unknown', 'Android'].includes(device) && (
              <AppBadge device="Android" />
            )}
            {['unknown', 'iOS'].includes(device) && <AppBadge device="iOS" />}
          </div>
        </div>
      </div>
    </section>
  );
}
